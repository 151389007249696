import { useStaticQuery, graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
interface QueryResult {
  sanityIndexPage: {
    heroImage: {
      alt: string;
      asset: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    pageBlocks: any;
  };
}

export const useIndexPageQuery = (): QueryResult => {
  const data = useStaticQuery(graphql`
    query {
      sanityIndexPage {
        heroImage {
          alt
          asset {
            gatsbyImageData(placeholder: NONE, formats: [AUTO, WEBP])
          }
        }
        pageBlocks {
          ... on SanityImageBlock {
            _key
            _type
            layout
            blockImage {
              alt
              asset {
                gatsbyImageData(placeholder: NONE, formats: [AUTO, WEBP])
              }
            }
            headlineSize
            blockHeadline
            blockText
          }
          ... on SanityInfoBlock {
            _key
            _type
            blockHeadline
            columnOneHeadline
            columnOneText
            columnThreeHeadline
            columnThreeText
            columnTwoHeadline
            columnTwoText
          }
          ... on SanityTimelineBlock {
            _key
            _type
            layout
            timelineImage {
              alt
              asset {
                gatsbyImageData(placeholder: NONE, formats: [AUTO, WEBP])
              }
            }
            blockHeadline
            timelineItems {
              timelineItemTitle
            }
          }
          ... on SanityContactBlock {
            _key
            _type
            layout
            blockImage {
              alt
              asset {
                gatsbyImageData(placeholder: NONE, formats: [AUTO, WEBP])
              }
            }
            blockHeadline
            blockText
          }
          ... on SanityTeamBlock {
            _key
            _type
            blockHeadline
            teamMembers {
              name
              role
              bio
              image {
                alt
                asset {
                  gatsbyImageData(placeholder: NONE, formats: [AUTO, WEBP])
                }
              }
            }
          }
          ... on SanitySliderBlock {
            _key
            _type
            layout
            headlineSize
            blockHeadline
            sliderItems {
              sliderText
              sliderAuthor
            }
          }
        }
      }
    }
  `);

  return data;
};
