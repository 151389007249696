import React from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
interface TeamBlockProps {
  key: string;
  _type: string;
  blockHeadline: string;
  teamMembers: {
    name: string;
    role: string;
    bio: string;
    image: {
      alt: string;
      asset: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  }[];
}

export const TeamBlock = (props: TeamBlockProps) => {
  const { _type, blockHeadline, teamMembers } = props;

  return (
    <section id={_type} className={`section`}>
      <h2>{blockHeadline}</h2>
      <div className="teamMembers">
        {teamMembers?.map((person, index) => (
          <div className="person" key={index}>
            <div className="info">
              <h4>{person.name}</h4>
              <p className="role">{person.role}</p>
              <p>{person.bio}</p>
            </div>
            <div className="image">
              {person.image && (
                <GatsbyImage
                  image={person.image.asset.gatsbyImageData}
                  alt={person.image.alt}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
