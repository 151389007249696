import React, { ReactNode, useContext } from "react";
import ThemeContext from "../context/ThemeContext";
import { Header } from "../components/header";
import Loader from "../svg/loader.inline.svg";

import "../styles/global.scss";
import "slick-carousel/slick/slick.css";

interface LayoutProps {
  children: ReactNode;
  className: string;
}

export default function Layout(props: LayoutProps) {
  const { children } = props;
  const { navigationOpen, overflow } = useContext(ThemeContext);

  return (
    <div style={{ overflow: overflow ? "hidden" : "auto" }}>
      <div id="loader-wrapper" className="loader-wrapper open">
        <Loader className="loader" />
      </div>
      <Header />
      <div className={`${navigationOpen ? "menu--open" : ""}`}>
        <main id="main" className="main">
          {children}
        </main>
      </div>
    </div>
  );
}
