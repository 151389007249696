import * as React from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
interface ImageBlockProps {
  layout: string;
  blockImage: {
    alt: string;
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  headlineSize: string;
  blockHeadline: string;
  blockText: string;
}

export const ImageBlock = (props: ImageBlockProps) => {
  const { layout, headlineSize, blockHeadline, blockText, blockImage } = props;
  const sectionClass =
    layout === "left"
      ? "left"
      : layout == "full"
      ? "full"
      : layout == "fullLeft"
      ? "fullLeft"
      : "right";

  return (
    <section className={`section ${sectionClass}`}>
      <div className="text">
        {blockText && (
          <div>
            {blockHeadline.length ? (
              [
                headlineSize == "Large" ? (
                  <h2 key={1}>{blockHeadline}</h2>
                ) : (
                  <h3 key={2}>{blockHeadline}</h3>
                ),
              ]
            ) : (
              <></>
            )}
            <p>{blockText}</p>
          </div>
        )}
      </div>
      <div className="image">
        <Controller>
          <Scene
            indicators={false}
            duration="250%"
            pin={false}
            triggerHook="onEnter"
            reverse={true}
          >
            <Timeline wrapper={<div className="parallax" />}>
              <Tween
                position="0"
                from={{
                  yPercent: -20,
                }}
                to={{
                  yPercent: 20,
                }}
                ease="none"
              >
                <div className="imageWrapper">
                  {blockImage && (
                    <GatsbyImage
                      image={blockImage.asset.gatsbyImageData}
                      alt={blockImage.alt}
                    />
                  )}
                </div>
              </Tween>
            </Timeline>
          </Scene>
        </Controller>
      </div>
    </section>
  );
};
