import * as React from "react";

interface InfoBlockProps {
  key: string;
  _type: string;
  blockHeadline: string;
  columnOneHeadline: string;
  columnOneText: string;
  columnTwoHeadline: string;
  columnTwoText: string;
  columnThreeHeadline: string;
  columnThreeText: string;
}

export const InfoBlock = (props: InfoBlockProps) => {
  const {
    _type,
    blockHeadline,
    columnOneHeadline,
    columnOneText,
    columnTwoHeadline,
    columnTwoText,
    columnThreeHeadline,
    columnThreeText,
  } = props;
  const sectionClass = "infoBlock";

  return (
    <section id={_type} className={`section ${sectionClass}`}>
      {blockHeadline.length && (
        <div className="headline">
          <h2>{blockHeadline}</h2>
        </div>
      )}

      <div className="info-columns">
        {columnOneHeadline.length && (
          <div className="column">
            <h3>{columnOneHeadline}</h3>
            <p>{columnOneText}</p>
          </div>
        )}

        {columnTwoHeadline.length && (
          <div className="column">
            <h3>{columnTwoHeadline}</h3>
            <p>{columnTwoText}</p>
          </div>
        )}

        {columnThreeHeadline.length && (
          <div className="column">
            <h3>{columnThreeHeadline}</h3>
            <p>{columnThreeText}</p>
          </div>
        )}
      </div>
    </section>
  );
};
