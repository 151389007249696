import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useIndexPageQuery } from "../queries/useIndexPageQuery";
import { Footer } from "../components/footer";
import { GatsbyImage } from "gatsby-plugin-image";
import { ImageBlock } from "../components/imageBlock";
import { InfoBlock } from "../components/infoBlock";
import { TimelineBlock } from "../components/timelineBlock";
import { SliderBlock } from "../components/sliderBlock";
import { ContactBlock } from "../components/contactBlock";
import { TeamBlock } from "../components/teamBlock";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";

const IndexPage = () => {
  const { sanityIndexPage } = useIndexPageQuery();
  const heroImage = sanityIndexPage.heroImage;
  const pageBlocks = sanityIndexPage.pageBlocks;

  return (
    <Layout className="home">
      <SEO title="Home" />
      <section id="heroBlock" className="hero-section">
        <div className="hero-image">
          <Controller>
            <Scene
              indicators={false}
              duration="250%"
              pin={false}
              triggerHook="onLeave"
              reverse={true}
            >
              <Timeline wrapper={<div className="parallax" />}>
                <Tween
                  position="0"
                  from={{
                    yPercent: 0,
                  }}
                  to={{
                    yPercent: 40,
                  }}
                  ease="none"
                >
                  <div className="heroImageWrapper">
                    {heroImage && (
                      <GatsbyImage
                        image={heroImage.asset.gatsbyImageData}
                        alt={heroImage.alt}
                      />
                    )}
                  </div>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        </div>
        <div className="hero-text">
          <h1>{heroImage.alt}</h1>
        </div>
      </section>
      <div id="imageBlock" />
      {pageBlocks.map((block: any) => {
        if (block._type === "imageBlock") {
          return (
            <ImageBlock
              key={block._key}
              layout={block.layout}
              blockImage={block.blockImage}
              headlineSize={block.headlineSize}
              blockHeadline={block.blockHeadline}
              blockText={block.blockText}
            />
          );
        } else if (block._type === "infoBlock") {
          return (
            <InfoBlock
              key={block._key}
              _type={block._type}
              blockHeadline={block.blockHeadline}
              columnOneHeadline={block.columnOneHeadline}
              columnOneText={block.columnOneText}
              columnTwoHeadline={block.columnTwoHeadline}
              columnTwoText={block.columnTwoText}
              columnThreeHeadline={block.columnThreeHeadline}
              columnThreeText={block.columnThreeText}
            />
          );
        } else if (block._type === "timelineBlock") {
          return (
            <TimelineBlock
              key={block._key}
              _type={block._type}
              layout={block.layout}
              timelineImage={block.timelineImage}
              blockHeadline={block.blockHeadline}
              timelineItems={block.timelineItems}
            />
          );
        } else if (block._type === "contactBlock") {
          return (
            <ContactBlock
              key={block._key}
              _type={block._type}
              layout={block.layout}
              blockImage={block.blockImage}
              blockHeadline={block.blockHeadline}
              blockText={block.blockText}
            />
          );
        } else if (block._type === "sliderBlock") {
          return (
            <SliderBlock
              key={block._key}
              _type={block._type}
              layout={block.layout}
              headlineSize={block.headlineSize}
              blockHeadline={block.blockHeadline}
              sliderItems={block.sliderItems}
            />
          );
        } else if (block._type === "teamBlock") {
          return (
            <TeamBlock
              key={block._key}
              _type={block._type}
              blockHeadline={block.blockHeadline}
              teamMembers={block.teamMembers}
            />
          );
        } else {
          return null;
        }
      })}
      <Footer />
    </Layout>
  );
};

export default IndexPage;
