import { useStaticQuery, graphql } from "gatsby";
interface QueryResult {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      author: string;
      lang: string;
      keywords: string;
    };
  };
}

export const useSiteMetadataQuery = (): QueryResult => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          lang
          keywords
        }
      }
    }
  `);

  return data;
};
