import React from "react";
import { useSiteSettingsQuery } from "../queries/useSiteSettingsQuery";
import INSTA from "../svg/insta-icon.inline.svg";
import FACE from "../svg/facebook-icon.inline.svg";

export const Footer = () => {
  const { sanitySiteSettings } = useSiteSettingsQuery();
  const {
    email,
    number,
    footerCopy,
    footerCredits,
    instagramUrl,
    facebookUrl,
  } = sanitySiteSettings;

  return (
    <footer>
      <div className="copy">
        <p>{footerCopy}</p>
      </div>
      <div className="contact">
        <div className="emailNum">
          <p>
            <a href={"mailto:" + email}>{email}</a>
          </p>
          <a href="tel:0402369899">{number}</a>
        </div>
        <div className="credits">
          <div className="credit-lines">
            {footerCredits.map(({ link, title }, index: number) => (
              <span key={index}>
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={title}
                >
                  {title}
                </a>
              </span>
            ))}
            <span>
              Brand:{" "}
              <a
                href="https://ellewilliams.com"
                target="_blank"
                rel="noreferrer"
              >
                Elle Williams Studio
              </a>
            </span>
            <span>
              Website:{" "}
              <a
                href="https://ellewilliams.com"
                target="_blank"
                rel="noreferrer"
              >
                Elle Williams Studio
              </a>{" "}
              &amp;{" "}
              <a href="https://srrycmpny.com/" target="_blank" rel="noreferrer">
                Sorry Company
              </a>
            </span>
          </div>
          <div className="social">
            <a href={facebookUrl} aria-label="Facebook Link">
              <FACE />
            </a>
            <a href={instagramUrl} aria-label="Instagram Link">
              <INSTA />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
