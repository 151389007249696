import React, { useContext } from "react";
import ThemeContext from "../context/ThemeContext";
import { useKeyPress } from "../hooks/useKeyPress";
import { useSiteMetadataQuery } from "../queries/useSiteMetadataQuery";
import Logo from "../svg/logo.inline.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";

export const Header = () => {
  const { site } = useSiteMetadataQuery();
  const { navigationOpen, toggleNavigation } = useContext(ThemeContext);
  const escapePress: boolean = useKeyPress("Escape");
  escapePress && toggleNavigation();

  return (
    <header id="topNav" className={` ${navigationOpen ? "menu--open" : ""}`}>
      <div className="nav-row">
        <div className="logo">
          <AnchorLink href="#main" className="logo-link">
            <Logo className="outline-logo" />
            <h1>{site.siteMetadata.title}</h1>
          </AnchorLink>
        </div>
        <div className="menu-trigger">
          <button
            className="btn menu-btn"
            onClick={() => toggleNavigation()}
            aria-haspopup="true"
            aria-label="Open Navigation"
            aria-expanded={navigationOpen}
          >
            <span>Menu</span>
          </button>
        </div>

        <nav id="nav" className={` ${navigationOpen ? "menu--open" : ""}`}>
          <ul className="items-baseline">
            <li>
              <AnchorLink href="#heroBlock" onClick={() => toggleNavigation()}>
                About
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#imageBlock" onClick={() => toggleNavigation()}>
                Our approach
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#infoBlock" onClick={() => toggleNavigation()}>
                What we offer
              </AnchorLink>
            </li>
            <li>
              <AnchorLink
                href="#timelineBlock"
                onClick={() => toggleNavigation()}
              >
                Our process
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#teamBlock" onClick={() => toggleNavigation()}>
                Team
              </AnchorLink>
            </li>
            <li>
              <AnchorLink
                href="#contactBlock"
                onClick={() => toggleNavigation()}
              >
                Contact
              </AnchorLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};
