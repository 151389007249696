import React, { useState } from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { useFormSubmission } from "../hooks/formSubmit";
import { FormField } from "../types/types";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
interface ContactBlockProps {
  key: string;
  _type: string;
  layout: string;
  blockImage: {
    alt: string;
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  blockHeadline: string;
  blockText: string;
}

const formConfig: FormField[] = [
  {
    initial: "",
    name: "Name",
    type: "text",
    element: "input",
    id: "Name",
    className: "mt-1 block w-full",
    placeholder: "Name",
  },
  {
    initial: "",
    name: "Email",
    type: "email",
    element: "input",
    id: "Email",
    className: "mt-1 block w-full",
    placeholder: "Email",
  },
  {
    initial: "",
    name: "Phone",
    type: "tel",
    element: "input",
    id: "Phone",
    className: "mt-1 block w-full",
    placeholder: "Phone",
  },
  {
    initial: "",
    name: "SiteAddress",
    type: "text",
    element: "input",
    id: "SiteAddress",
    className: "mt-1 block w-full",
    placeholder: "Site Address",
  },
  {
    initial: "",
    name: "Message",
    type: "text",
    element: "input",
    id: "Message",
    className: "mt-1 block w-full",
    placeholder: "Message",
  },
  {
    initial: "",
    name: "bot-field",
    type: "text",
    element: "input",
    id: "b-field",
    className: "invisible",
    placeholder: "fill me out",
  },
];

export const ContactBlock = (props: ContactBlockProps) => {
  const { layout, blockHeadline, blockText, blockImage, _type } = props;
  const sectionClass =
    layout === "left" ? "left" : layout == "full" ? "full" : "right";

  const { formState, fieldsState, submitForm, updateField } =
    useFormSubmission(formConfig);

  const [rows, setRows] = useState(1);

  const handleTextAreaChange = (e: any) => {
    e.preventDefault();
    setRows(e.target.value.split("\n").length);
  };

  return (
    <section id={_type} className={`section ${sectionClass}`}>
      <div className="text form">
        <div>
          {blockHeadline.length ? (
            [<h2 key={1}>{blockHeadline}</h2>, <p key={2}>{blockText}</p>]
          ) : (
            <></>
          )}

          <form
            method="POST"
            name="contact"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={submitForm}
          >
            <div className="grid grid-cols-6 gap-6">
              <div className="input">
                <label htmlFor="Name">
                  <div className="hide">Name</div>
                  <input
                    type="text"
                    name="Name"
                    value={fieldsState["Name"]}
                    onChange={updateField}
                    placeholder="Name"
                    required
                  />
                  <span></span>
                </label>
              </div>
              <div className="input">
                <label htmlFor="Email">
                  <div className="hide">Email address</div>
                  <input
                    type="text"
                    name="Email"
                    value={fieldsState["Email"]}
                    onChange={updateField}
                    placeholder="Email Address"
                    required
                  />
                  <span></span>
                </label>
              </div>
              <div className="input">
                <label htmlFor="Phone">
                  <div className="hide">Phone</div>
                  <input
                    type="text"
                    name="Phone"
                    value={fieldsState["Phone"]}
                    onChange={updateField}
                    placeholder="Phone"
                    required
                  />
                  <span></span>
                </label>
              </div>
              <div className="input">
                <label htmlFor="SiteAddress">
                  <div className="hide">Site Address</div>
                  <input
                    type="text"
                    name="SiteAddress"
                    value={fieldsState["SiteAddress"]}
                    onChange={updateField}
                    placeholder="Site Address"
                    required
                  />
                  <span></span>
                </label>
              </div>
              <div className="input">
                <label htmlFor="Message">
                  <div className="hide">Message</div>
                  <textarea
                    type="text"
                    name="Message"
                    value={fieldsState["Message"]}
                    onChange={updateField}
                    onKeyUp={(e) => handleTextAreaChange(e)}
                    placeholder="Message"
                    required
                    rows={rows}
                  />
                  <span></span>
                </label>
              </div>

              <input type="hidden" name="form-name" value="contact" />
              <div className="input submit">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent"
                >
                  {formState === "SUBMITTING" && "Submitting..."}
                  {formState === "ERROR" && "Error. Try Again."}
                  {formState === "INITIAL" && "Submit"}
                  {formState === "SUCCESS" &&
                    "Thank you! We'll be in touch shortly"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="image">
        <Controller>
          <Scene
            indicators={false}
            duration="250%"
            pin={false}
            triggerHook="onEnter"
            reverse={true}
          >
            <Timeline wrapper={<div className="parallax" />}>
              <Tween
                position="0"
                from={{
                  yPercent: -20,
                }}
                to={{
                  yPercent: 20,
                }}
                ease="none"
              >
                <div className="imageWrapper">
                  {blockImage && (
                    <GatsbyImage
                      image={blockImage.asset.gatsbyImageData}
                      alt={blockImage.alt}
                    />
                  )}
                </div>
              </Tween>
            </Timeline>
          </Scene>
        </Controller>
      </div>
    </section>
  );
};
