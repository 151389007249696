import React from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import useDimensions from "react-use-dimensions";

interface TimelineBlockProps {
  key: string;
  _type: string;
  layout: string;
  timelineImage: {
    alt: string;
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  blockHeadline: string;
  timelineItems: {
    timelineItemTitle: string;
  }[];
}
export const TimelineBlock = (props: TimelineBlockProps) => {
  const { _type, timelineImage, layout, blockHeadline, timelineItems } = props;
  const sectionClass = layout === "left" ? "left" : "right";
  const [timelineWrapperRef, { height }] = useDimensions();

  return (
    <section id={_type} className={`section ${sectionClass}`}>
      <div className="image">
        <Controller>
          <Scene
            indicators={false}
            duration="250%"
            pin={false}
            triggerHook="onEnter"
            reverse={true}
          >
            <Timeline wrapper={<div className="parallax" />}>
              <Tween
                position="0"
                from={{
                  yPercent: -20,
                }}
                to={{
                  yPercent: 20,
                }}
                ease="none"
              >
                <div className="imageWrapper">
                  {timelineImage && (
                    <GatsbyImage
                      image={timelineImage.asset.gatsbyImageData}
                      alt={timelineImage.alt}
                    />
                  )}
                </div>
              </Tween>
            </Timeline>
          </Scene>
        </Controller>
      </div>

      <div
        className="timelineCol"
        data-scroll
        data-scroll-repeat="true"
        data-scroll-speed="1"
        data-scroll-call="updateHeight"
        data-height="0"
      >
        <h2>{blockHeadline}</h2>
        <div className="timelineWrapper" ref={timelineWrapperRef}>
          <div id="timelineBorder">
            <Controller>
              <Scene
                duration={height - 30}
                triggerElement=".timelineWrapper"
                pin={false}
                reverse={true}
                indicators={false}
              >
                <Tween
                  from={{
                    height: "0px",
                  }}
                  to={{
                    height: "100%",
                  }}
                  ease="none"
                  paused
                >
                  <div id="svg-height">
                    <svg
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 3 306"
                      preserveAspectRatio="none"
                    >
                      <path
                        vectorEffect="non-scaling-stroke"
                        className="cls-1"
                        d="M2,0V306"
                        transform="translate(-0.5 0)"
                        strokeWidth="3px"
                      />
                    </svg>
                  </div>
                </Tween>
              </Scene>
            </Controller>
          </div>
          {timelineItems?.map((timeline, index) => (
            <div className="timelineItem" key={index}>
              <Controller>
                <Scene
                  duration={10}
                  triggerElement={this}
                  pin={false}
                  reverse={true}
                  indicators={false}
                >
                  <Tween
                    from={{
                      backgroundColor: "var(--color-secondary",
                    }}
                    to={{
                      backgroundColor: "var(--color-primary)",
                    }}
                    ease="none"
                    paused
                  >
                    <div className="bubble" />
                  </Tween>
                </Scene>
              </Controller>
              <div className="timelineText">
                <p>{timeline.timelineItemTitle}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
