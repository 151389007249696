import React, { useRef, useState } from "react";
import Slider from "react-slick";

interface SliderBlockProps {
  key: string;
  _type: string;
  layout: string;
  headlineSize: string;
  blockHeadline: string;
  sliderItems: {
    sliderText: string;
    sliderAuthor: string;
  }[];
}

export const SliderBlock = (props: SliderBlockProps) => {
  const { _type, layout, blockHeadline, headlineSize, sliderItems } = props;
  const sectionClass = layout === "left" ? "left" : "right";

  const [slideIndex, setSlideIndex] = useState(0);
  const slider1 = useRef(null);

  function afterChangeHandler(currentSlide: number) {
    setSlideIndex(currentSlide);
  }

  function next() {
    slider1.current.slickNext();
  }

  function previous() {
    slider1.current.slickPrev();
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id={_type} className={`section ${sectionClass}`}>
      <div className="sliderText">
        {blockHeadline.length ? (
          [
            headlineSize == "Large" ? (
              <h2 key={1}>{blockHeadline}</h2>
            ) : (
              <h3 key={2}>{blockHeadline}</h3>
            ),
          ]
        ) : (
          <></>
        )}
        <Slider
          {...settings}
          ref={slider1}
          className="slider"
          afterChange={afterChangeHandler}
        >
          {sliderItems.map(function (slide, index) {
            return (
              <div key={index}>
                <h3 className="slick-slide-text">{slide.sliderText}</h3>
                <h3 className="slick-slide-author">{slide.sliderAuthor}</h3>
              </div>
            );
          })}
        </Slider>
        <div className="pagination">
          <p>
            <button className="button prev" onClick={previous}>
              ←
            </button>
            {slideIndex + 1}/{sliderItems.length}
            <button className="button next" onClick={next}>
              →
            </button>
          </p>
        </div>
      </div>
    </section>
  );
};
